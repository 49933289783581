/**
 * 白名单内的接口及js会监控其耗时及大小
 */

/** 接口白名单 */
export const apiWhiteList = [
  '/api/productInfo/realTimeData/query',
  '/api/productInfo/productDetail/get',
  '/api/ccc/publicBanner/get',
  '/api/home/pageConfig/get',
  '/api/home/switchChannel/get',
  '/api/category/list/get',
  '/api/category/channel/get',
  '/api/category/crowd/info/get',
  '/api/cart/getCart/get',
  '/api/cart/postGetPromotionById/get',
  '/api/cart/postFreeMall/get',
  '/api/user/dashboard/base/get',
  '/api/user/account_setting/getAccInfo/get',
  '/api/ccc/campaignsPageData/get',
  '/api/auth/initData/get',
  '/api/productList/info/get',
  '/api/user/addressbook/addressCheckRule/get',
  '/api/user/addressbook/addressById/get',
  '/api/user/addressbook/countrySensitiveList/query',
  '/api/user/addressbook/accountAndOrderEmail/get',
  '/api/user/addressbook/limitAddr/query',
  '/api/user/addressbook/addressFormConfigByInfo/get',
]

/** js白名单 */
export const jsWhiteList = [
  'goods_detail',
  'config_index',
  'category',
  'cart_index_new',
  'user',
  'user-index',
  'ccc-campaigns',
  'login-module',
  'plv2_container',
  'category',
]
