import UserInfoManager from 'public/src/services/UserInfoManager'
import { getLocalStorage, removeLocalStorage } from '@shein/common-function'
import { clickSwitchAccount } from '@login/utils/loginAnalysis/multiAccount.js'
import { removeServiceWorkerCache } from '@login/utils/clearSwCache.js'
import { USERINFO_KEY } from '@login/common/contants.js'

export const setAuthUserInfo = (data) => {
  const member = data.info?.member || {}
  const ugid = data.info?.ugid || ''
  removeServiceWorkerCache()
  UserInfoManager.set({ key: 'UserInfo', value: member, actionType: 'setAuthUserInfo' })
  UserInfoManager.set({ key: 'ugid', value: ugid, actionType: 'setUgidUserInfo' })
  window.isLoginSuccessRefresh = true
}

export const removeAuthUserInfo = () => {
  try {
    if (getLocalStorage(USERINFO_KEY)) {
      removeLocalStorage(USERINFO_KEY)
    }
    // 清空ugid, 防止二次落地获取的数据为登录的数据
    UserInfoManager.clearStorage({ key: 'ugid' })
  } catch (error) {
    console.log( error + 'removeAuthUserInfo error')
  }
}

export const handleSwitchAccount = () => {
  clickSwitchAccount()
  SHEIN_LOGIN && SHEIN_LOGIN.show({
    show: true,
    bi: 'switch_account',
    isSwitchAccount: true
  })
}


export const loadRememberModal = async (config) => {
  const rememberModal = (await import('public/src/pages/login/common/logout.js')).default
  rememberModal?.$children[0]?._setupProxy?.handleShowRememberModal(config)
}
